import React from 'react';
import './Footer.scss';
import FooterLogo from '../../images/logo.png';
import { BsTelephone } from 'react-icons/bs';
import { BsEnvelope } from 'react-icons/bs';
import { MdLocationOn } from 'react-icons/md';
const Footer = () => {
	const year = new Date().getFullYear();
	return (
		<footer>
			<div className='container'>
				<div className='footer-top'>
					<ul>
						<li>
							<h3>Telefon: </h3>
							<div className='info-items'>
								<span>
									<BsTelephone /> +381 63 109 21 00 Srbija
								</span>
								<span>
									<BsTelephone /> +387 51 264 888 BiH
								</span>
								<span>
									<BsTelephone /> +382 69 691 910 Crna Gora
								</span>
							</div>
						</li>
						<li>
							<h3>Lokacija: </h3>
							<div className='info-items'>
								<span>
									<MdLocationOn /> Bul.Nemanjica19/56 Niš Srbija
								</span>
								<span>
									<MdLocationOn /> Gavre Vukčkovića 4 Banja Luka BiH
								</span>
							</div>
						</li>
						<li>
							<h3>Email: </h3>
							<div className='info-items'>
								<span>
									<BsEnvelope /> gopaddling.rs@gmail.com Srbija
								</span>
								<span>
									<BsEnvelope /> gopaddling.me@gmail.com Crna Gora
								</span>
								<span>
									<BsEnvelope /> info@itexpertstudio.com BiH
								</span>
							</div>
						</li>
					</ul>
				</div>
				<hr />
				<div className='footer-bottom'>
					<div className='footer-logo'>
						<img src={FooterLogo} alt='IT Expert Studio' width='150' />
					</div>
					<div className='copyright'>
						<p>
							©<span>{year}</span>{' '}
							<a href='https://itexpertstudio.com/' rel='noreferrer'>
								IT Expert.
							</a>{' '}
							Sva prava zadržana.
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
