import React from 'react';
import LoaderImg from '../../images/loading.svg';
import './Loader.scss';

const Loader = () => {
	return (
		<div className='loader'>
			<img src={LoaderImg} alt='Loader' />
		</div>
	);
};

export default Loader;
