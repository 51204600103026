import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isChoosen: false,
};

const isLanguageChosen = createSlice({
	name: 'isLanguageChoosen',
	initialState,
	reducers: {
		changeIsChoosen: (state) => {
			state.isChoosen = true;
		},
	},
});

export const { changeIsChoosen } = isLanguageChosen.actions;
export default isLanguageChosen.reducer;
