import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	products: null,
	isLoading: true,
};

const productsSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {
		addProducts: (state, { payload }) => {
			state.products = payload;
			state.isLoading = false;
		},
		removeProducts: (state) => {
			state.products = null;
			state.isLoading = true;
		},
	},
});

export const { addProducts, removeProducts } = productsSlice.actions;
export default productsSlice.reducer;
