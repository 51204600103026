import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import './PopularProducts.scss';
import Loader from '../loader/Loader';

const PopularProducts = () => {
	const [products, setProducts] = useState(null);

	useEffect(() => {
		axios
			.get(
				'https://newproject.store/kajaci/wp-json/wc/v3/products?category=26&per_page=4&page=1&consumer_key=ck_1427e438064835f715d903b3bc1237a900a4aa70&consumer_secret=cs_fe3e08977975caf69e0d2028144859d482730be8'
			)
			.then((res) => {
				console.log(res.data);
				setProducts(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	const content =
		products !== null ? (
			products.map((product) => {
				return (
					<article className='product' key={product.id}>
						{product.images[1] ? (
							<div className='image'>
								<img src={product.images[1].src} alt={product.name} />
							</div>
						) : (
							<p>Proizvod nema sliku</p>
						)}

						<div className='content'>
							<h3>{product.name}</h3>
							<Link
								className='btn'
								to={`proizvodi/${product.categories[0].id}/${product.id}`}
							>
								Vidi Više
							</Link>
						</div>
					</article>
				);
			})
		) : (
			<Loader />
		);
	return (
		<section className='popular-products'>
			<div className='container'>
				<div className='title'>
					<h2>popularni proizvodi</h2>
				</div>
				<div className='wrapper'>{content}</div>
				<div className='btn-group'>
					<Link to='kategorije' className='btn'>
						svi proizvodi
					</Link>
				</div>
			</div>
		</section>
	);
};

export default PopularProducts;
