import { configureStore } from '@reduxjs/toolkit';
import cartReducer from '../features/cart/cartSlice';
import langReducer from '../features/lang/langSlice';
import modalReduser from '../features/modal/modalSlice';
import productsReducer from '../features/products/productsSlice';
import categoriesReducer from '../features/categories/categoriesSlice';
import productReducer from '../features/single-product/productSlice';
import currencyReducer from '../features/currency/currencySlice';
import userFormReducer from '../features/user-form/userFormSlice';
import isLanguageCoosenReducer from '../features/is-language-choosen/isLanguageChosen';

export const store = configureStore({
	reducer: {
		cart: cartReducer,
		lang: langReducer,
		modal: modalReduser,
		products: productsReducer,
		categories: categoriesReducer,
		product: productReducer,
		currency: currencyReducer,
		userForm: userFormReducer,
		isLanguageChoosen: isLanguageCoosenReducer,
	},
});
