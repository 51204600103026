import React from 'react';
import { useSelector } from 'react-redux';
import SingleCategory from '../single-category/SingleCategory';
import './Categories.scss';

const Categories = () => {
	const { categories } = useSelector((state) => state.categories);
	const categoriesArr = [
		{
			id: categories !== null ? categories.product_categories[2].id : '1',
			link: `proizvodi/${
				categories !== null ? categories.product_categories[2].id : null
			}`,
			bcgImage:
				categories !== null ? categories.product_categories[2].image : null,
			title: categories !== null ? categories.product_categories[2].name : null,
			content:
				categories !== null
					? categories.product_categories[2].description
					: null,
		},
		{
			id: categories !== null ? categories.product_categories[8].id : '2',
			link: `proizvodi/${
				categories !== null ? categories.product_categories[8].id : null
			}`,
			bcgImage:
				categories !== null ? categories.product_categories[8].image : null,
			title: categories !== null ? categories.product_categories[8].name : null,
			content:
				categories !== null
					? categories.product_categories[8].description
					: '3',
		},
		{
			id: categories !== null ? categories.product_categories[1].id : '4',
			link: `proizvodi/${
				categories !== null ? categories.product_categories[1].id : null
			}`,
			bcgImage:
				categories !== null ? categories.product_categories[1].image : null,
			title: categories !== null ? categories.product_categories[1].name : null,
			content:
				categories !== null
					? categories.product_categories[1].description
					: null,
		},

		{
			id: categories !== null ? categories.product_categories[3].id : '3',
			link: `kategorije/${
				categories !== null ? categories.product_categories[3].id : null
			}`,
			bcgImage:
				categories !== null ? categories.product_categories[3].image : null,
			title: categories !== null ? categories.product_categories[3].name : null,
			content:
				categories !== null
					? categories.product_categories[3].description
					: null,
		},
	];

	const categoryContent = categoriesArr.map((category) => {
		return (
			<SingleCategory
				key={category.id}
				category={category.link}
				title={category.title}
				bcgImage={category.bcgImage}
				content={category.content}
			/>
		);
	});
	return (
		<section className='categories'>
			<div className='title'>
				<h2>Vodeni sportovi</h2>
				<p>
					U posljednjih 20 godina razvili smo niz usluga namjenjenih svim
					vrstama surfera: onima na visokom nivou kao i početnicima, ženama,
					djeci, turistima, roditeljima ili samo ljubiteljima plaže. Sa nama
					ćete doživjeti najbolje iskustvo na vodi!
				</p>
			</div>
			<div className='wrapper'>{categoryContent}</div>
		</section>
	);
};

export default Categories;
