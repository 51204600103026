import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	currency: null,
};

const currencySlice = createSlice({
	name: 'currency',
	initialState,
	reducers: {
		addCurrency: (state, { payload }) => {
			state.currency = payload;
		},
	},
});

export const { addCurrency } = currencySlice.actions;
export default currencySlice.reducer;
