import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../images/icon_services_box.png';
import './SingleCategory.scss';

const SingleCategory = (props) => {
	return (
		<article className='category-item'>
			<Link to={`/${props.category}`}>
				<div
					className='front'
					style={{ backgroundImage: `url(${props.bcgImage})` }}
				>
					<h3>{props.title}</h3>
				</div>
				<div className='back'>
					<img width='130' height='130' src={Icon} alt='Valovi' />
					<p>{props.content}</p>
				</div>
			</Link>
		</article>
	);
};

export default SingleCategory;
