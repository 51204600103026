import React, { useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { addLang } from '../../features/lang/langSlice';
import { changeIsChoosen } from '../../features/is-language-choosen/isLanguageChosen';
import { useDispatch } from 'react-redux';

import './ChooseLanguage.scss';

const ChooseLanguage = () => {
	const dispatch = useDispatch();
	const [lng, setLng] = useState('RS');

	const onSelectFlag = (countryCode) => {
		setLng(countryCode);
	};
	const btnlickHandler = () => {
		dispatch(addLang(lng));
		dispatch(changeIsChoosen());
	};

	return (
		<section className='choose-language'>
			<div className='wrapper'>
				<h2>Izaberite zemlju iz menija: </h2>
				<ReactFlagsSelect
					countries={['RS', 'BA', 'ME']}
					customLabels={{
						RS: 'SRB',
						BA: 'BiH',
						ME: 'CG',
					}}
					selected={lng}
					onSelect={onSelectFlag}
					fullWidth={false}
					className='lng-select'
					selectButtonClassName='lng-button'
				/>
				<button className='choose' onClick={btnlickHandler}>
					IZABERI
				</button>
			</div>
		</section>
	);
};

export default ChooseLanguage;
