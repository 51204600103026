import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../../features/modal/modalSlice';
import { clearCart } from '../../features/cart/cartSlice';
import { addActive } from '../../features/user-form/userFormSlice';
import './CartModal.scss';
import CartyItem from './CartyItem';

const CartModal = () => {
	const dispatch = useDispatch();
	const { isOpen } = useSelector((state) => state.modal);
	const { lang } = useSelector((state) => state.lang);
	const { currency } = useSelector((state) => state.currency);
	const { cartItems, amount, total } = useSelector((state) => state.cart);
	const [valute, setValute] = useState('');
	const [curr, setCurr] = useState(0);

	useEffect(() => {
		switch (lang) {
			case 'RS':
				setValute('RSD');
				setCurr(currency !== null && currency[0].description);
				break;
			case 'BA':
				setValute('BAM');
				setCurr(currency !== null && currency[1].description);
				break;
			case 'ME':
				setValute('EUR');
				setCurr('1');
				break;
			default:
				setValute('RSD');
				setCurr(currency !== null && currency[0].description);
				break;
		}
	}, [lang, cartItems]);

	const content =
		cartItems.length > 0 ? (
			cartItems.map((item) => {
				return (
					<CartyItem
						key={item.id}
						id={item.id}
						productName={item.productName}
						qty={item.qty}
						valute={valute}
						curr={curr}
						price={item.price}
						image={item.image}
					/>
				);
			})
		) : (
			<p>Nema proizvoda u korpi!</p>
		);
	return (
		<aside className={`cart-modal${isOpen ? ' active' : ''}`}>
			<div className='cart-header'>
				<h2>Odabrani proizvodi:</h2>
				<button className='close' onClick={() => dispatch(closeModal())}>
					X
				</button>
			</div>
			<ul>{content}</ul>
			<div className='total-info'>
				<p>
					Ukupno proizvoda: <span>{amount}</span>
				</p>
				<p>
					Ukupna cijena:{' '}
					<span>
						{total > 0 ? Math.round(total * parseFloat(curr)) : 0}
						{valute}
					</span>
				</p>
			</div>
			{cartItems.length > 0 ? (
				<div className='btn-group'>
					<button
						onClick={() => {
							dispatch(clearCart());
						}}
					>
						Obriši izabrane proizvode
					</button>
					<button
						onClick={() => {
							dispatch(addActive());
							dispatch(closeModal());
						}}
					>
						Naruči izabrane proizvode
					</button>
				</div>
			) : null}
		</aside>
	);
};

export default CartModal;
