import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Header from '../header/Header';
import Categories from '../categories/Categories';

import { useSelector } from 'react-redux';

import Loader from '../loader/Loader';

const CategoriesPage = () => {
	const { categories, isLoading } = useSelector((state) => state.categories);
	const [path, setPath] = useState('');
	useEffect(() => {
		setPath(window.location.href);
	}, []);

	return (
		<>
			<Helmet>
				<title>Kajaci, SUP daske i daske za jedrenje na vjetru</title>
				<meta
					name='description'
					content='Tahe, brend koji Vam nudi mnoštvo izbora opreme za kajakaštvo, daske za vodu i jedrenje na vodi.'
				/>
				<meta property='og:url' content={path} />
				<meta property='og:type' content='website' />
				<meta
					property='og:title'
					content='Kajaci, SUP daske i daske za jedrenje na vjetru'
				/>
				<meta
					property='og:description'
					content='Tahe, brend koji Vam nudi mnoštvo izbora opreme za kajakaštvo, daske za vodu i jedrenje na vodi.'
				/>
				<meta
					property='og:image'
					content='https://newproject.store/kajaci/wp-content/uploads/2022/06/kategorije-meta-min.jpg'
				/>
			</Helmet>
			<div className='ceagories-page'>
				{isLoading ? (
					<Loader />
				) : (
					<>
						<Header
							bcgImage={
								categories !== null
									? categories.product_categories[1].image
									: null
							}
							title='Kategorije'
							content='Bilo da želite da "hodate po vodi" ili želite da veslate na moru, jezeru ili niz rijeku, ili se možda odlučite za najuzbudljiviju varijantu jedrenja na vjetru, Tahe je odličan izbor jer Vam nudi najkvalitetniju opremu za bilo koji vodeni sport.'
						/>

						<Categories />
					</>
				)}
			</div>
		</>
	);
};

export default CategoriesPage;
