import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	product: null,
	isLoading: true,
};

const productSlice = createSlice({
	name: 'product',
	initialState,
	reducers: {
		addProduct: (state, { payload }) => {
			state.product = payload;
			state.isLoading = false;
		},
		onUnmount: (state) => {
			state.product = null;
			state.isLoading = true;
		},
	},
});

export const { addProduct, onUnmount } = productSlice.actions;
export default productSlice.reducer;
