import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import ReactFlagsSelect from 'react-flags-select';
import Logo from '../../images/logo.png';
import { addLang } from '../../features/lang/langSlice';
import { openModal } from '../../features/modal/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import './NavBar.scss';

const NavBar = () => {
	const dispatch = useDispatch();
	const { amount } = useSelector((state) => state.cart);
	const { lang } = useSelector((state) => state.lang);
	const [lng, setLng] = useState(lang);

	const [isActive, setIsActive] = useState(false);
	const [isFixed, setIsFixed] = useState(false);
	const mobBtnHandler = (ev) => {
		ev.preventDefault();
		setIsActive(!isActive);
	};
	const restoreActive = () => {
		setIsActive(false);
	};
	const fixedClassHandler = () => {
		let yScroll = window.scrollY;

		if (yScroll >= 150) {
			setIsFixed(true);
		} else {
			setIsFixed(false);
		}
	};
	const onSelectFlag = (countryCode) => {
		setLng(countryCode);
		dispatch(addLang(countryCode));
	};
	window.addEventListener('scroll', fixedClassHandler);
	return (
		<div className='navbar'>
			<div className='navbar-top'>
				<ReactFlagsSelect
					countries={['RS', 'BA', 'ME']}
					customLabels={{
						RS: 'SRB',
						BA: 'BiH',
						ME: 'CG',
					}}
					selected={lang}
					onSelect={onSelectFlag}
					fullWidth={false}
					className='lng-select'
					selectButtonClassName='lng-button'
				/>
				<button className='cart-btn' onClick={() => dispatch(openModal())}>
					<AiOutlineShoppingCart />
					<span>{amount}</span>
				</button>
			</div>
			<nav className={`navbar-menu${isFixed ? ' fixed' : ''}`}>
				<div className='container'>
					<div className='brand'>
						<Link to='/'>
							<img src={Logo} alt='IT Expert Studio' width='150' />
						</Link>
					</div>
					<div className='mob-button-wrapper'>
						<button
							className={`mob-button${isActive ? ' active' : ''}`}
							onClick={mobBtnHandler}
						>
							<span></span>
							<span></span>
							<span></span>
						</button>
					</div>
					<div className={`menu${isActive ? ' active' : ''}`}>
						<ul className='menu-list'>
							<li>
								<NavLink to='/' onClick={restoreActive}>
									Početna
								</NavLink>
							</li>
							<li>
								<NavLink to='/kategorije' onClick={restoreActive}>
									Kategorije
								</NavLink>
							</li>
							<li>
								<NavLink to='/o-nama' onClick={restoreActive}>
									O nama
								</NavLink>
							</li>
							<li>
								<NavLink to='/kontakt' onClick={restoreActive}>
									Kontakt
								</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</div>
	);
};

export default NavBar;
