import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import Categories from '../categories/Categories';
import Header from '../header/Header';
import Bcg from '../../images/1.jpg';
import AboutHome from '../about-home/AboutHome';
import Map from '../map/Map';
import PopularProducts from '../popular/PopularProducts';

const Home = () => {
	const [path, setPath] = useState('');
	useEffect(() => {
		setPath(window.location.href);
	}, []);
	return (
		<>
			<Helmet>
				<title>Vodeni sportovi uz TAHE brend</title>
				<meta
					name='description'
					content='Tahe brend je zadužen za proizvodnju i dizajn kajaka, SUP daski i daski za jedrenje na vjetru'
				/>
				<meta property='og:url' content={path} />
				<meta property='og:type' content='website' />
				<meta property='og:title' content='Vodeni sportovi uz TAHE brend' />
				<meta
					property='og:description'
					content='Tahe brend je zadužen za proizvodnju i dizajn kajaka, SUP daski i daski za jedrenje na vjetru'
				/>
				<meta
					property='og:image'
					content='https://newproject.store/kajaci/wp-content/uploads/2022/06/pocetna-meta-min.jpg'
				/>
			</Helmet>
			<div className='home'>
				<Header
					bcgImage={Bcg}
					title='Sportovi na vodi u svim oblicima, bilo da se odlučite za jedrenje na vjetru, daske ili kajak. '
					content='#igrabezgranica'
				/>
				<PopularProducts />
				<AboutHome />
				<Categories />
				<Map />
			</div>
		</>
	);
};

export default Home;
