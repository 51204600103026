import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CategoriesPage from './components/pages/CategoriesPage';
import About from './components/pages/About';
import Category from './components/pages/Category';
import Contact from './components/pages/Contact';
import Home from './components/pages/Home';
import LayOut from './LayOut';
import Products from './components/pages/Products';
import Product from './components/pages/Product';
import { addCategories } from './features/categories/categoriesSlice';
import { addCurrency } from './features/currency/currencySlice';
import axios from 'axios';
import Error404 from './components/pages/404';
const credential =
	'consumer_key=ck_1427e438064835f715d903b3bc1237a900a4aa70&consumer_secret=cs_fe3e08977975caf69e0d2028144859d482730be8';
function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		axios
			.get(
				`https://newproject.store/kajaci//wc-api/v3/products/categories?${credential}`
			)
			.then((res) => {
				dispatch(addCategories(res.data));
			})
			.catch((err) => {
				console.log(err);
			});
	});
	useEffect(() => {
		axios
			.get(
				`https://newproject.store/kajaci/wp-json/wc/v3/products/tags?${credential}`
			)
			.then((res) => {
				dispatch(addCurrency(res.data));
			})
			.catch((err) => {
				console.log(err);
			});
	});
	return (
		<Router>
			<main className='App'>
				<Routes>
					<Route path='/' element={<LayOut />}>
						<Route index element={<Home />} />
						<Route path='kategorije'>
							<Route index element={<CategoriesPage />} />
							<Route path=':kategorijaId' element={<Category />} />
						</Route>
						<Route path='proizvodi/:id/'>
							<Route index element={<Products />} />
							<Route path=':proizvodId' element={<Product />} />
						</Route>
						<Route path='o-nama' element={<About />} />
						<Route path='kontakt' element={<Contact />} />
						<Route path='*' element={<Error404 />} />
					</Route>
				</Routes>
			</main>
		</Router>
	);
}

export default App;
