import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
	addProducts,
	removeProducts,
} from '../../features/products/productsSlice';
import { Helmet } from 'react-helmet';
import Header from '../header/Header';
import axios from 'axios';
import Loader from '../loader/Loader';

import './Products.scss';

const credential =
	'consumer_key=ck_1427e438064835f715d903b3bc1237a900a4aa70&consumer_secret=cs_fe3e08977975caf69e0d2028144859d482730be8';

const Products = () => {
	const dispatch = useDispatch();
	const { products, isLoading } = useSelector((state) => state.products);
	const { categories } = useSelector((state) => state.categories);
	const [path, setPath] = useState('');

	const { id } = useParams();

	useEffect(() => {
		setPath(window.location.href);
	}, []);

	const choosenCategory =
		categories !== null
			? categories.product_categories.find((item) => {
					return item.id === Number(id);
			  })
			: null;
	useEffect(() => {
		dispatch(removeProducts());
		axios
			.get(
				`https://newproject.store/kajaci/wp-json/wc/v3/products?category=${id}&per_page=100&page=1&${credential}`
			)
			.then((res) => {
				dispatch(addProducts(res.data));
			})
			.catch((err) => {
				console.log(err);
			});
	}, [dispatch, id]);
	const content =
		products !== null
			? products.map((product) => {
					return (
						<article className='product' key={product.id}>
							{product.images[1] ? (
								<div className='image'>
									<img src={product.images[1].src} alt={product.name} />
								</div>
							) : (
								<p>Proizvod nema sliku</p>
							)}

							<div className='content'>
								<h3>{product.name}</h3>
								<Link className='btn' to={`${product.id}`}>
									Vidi Više
								</Link>
							</div>
						</article>
					);
			  })
			: null;
	return (
		<>
			<Helmet>
				<title>Kajaci, SUP daske i daske za jedrenje na vjetru</title>
				<meta
					name='description'
					content='Proizvodi Web Shop Prodaja - Kajak, SUP daske,daske za  jedrenje na vjetru'
				/>
				<meta property='og:url' content={path} />
				<meta property='og:type' content='website' />
				<meta
					property='og:title'
					content='Kajaci, SUP daske i daske za jedrenje na vjetru'
				/>
				<meta
					property='og:description'
					content='Proizvodi Web Shop Prodaja - Kajak, SUP daske,daske za  jedrenje na vjetru'
				/>
				<meta
					property='og:image'
					content='https://newproject.store/kajaci/wp-content/uploads/2022/06/proizvodi-meta-min.jpg'
				/>
			</Helmet>
			{isLoading ? (
				<Loader />
			) : (
				<div className='products'>
					<Header
						bcgImage={choosenCategory.image}
						title={choosenCategory.name}
						content={choosenCategory.description}
					/>
					<section className='product-items'>
						<div className='container-lg'>{content}</div>
					</section>
				</div>
			)}
		</>
	);
};

export default Products;
