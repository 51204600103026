import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isActive: false,
};

const userFormSlice = createSlice({
	name: 'userForm',
	initialState,
	reducers: {
		addActive: (state) => {
			state.isActive = true;
		},
		removeActive: (state) => {
			state.isActive = false;
		},
	},
});
export const { addActive, removeActive } = userFormSlice.actions;

export default userFormSlice.reducer;
