import React from 'react';
import Fancybox from '../fancybox/Fancybox';
import { useSelector } from 'react-redux';
import Play from '../../images/play.png';
import BcgAnch from '../../images/hqdefault_7_12.jpg';
import './SingleProductSlider.scss';

const SingleProductSlider = () => {
	const { product } = useSelector((state) => state.product);
	const addActiveClassHandler = (ev) => {
		ev.preventDefault();
		let target = ev.target;
		let id;
		if (ev.target.tagName === 'IMG') {
			id = target.parentElement.getAttribute('href');
		}
		document
			.querySelectorAll('.image-container li')
			.forEach((item) => item.classList.remove('active'));
		document.getElementById(id).classList.add('active');
	};
	let content =
		product !== null
			? product.images
					.filter((item, key) => key !== 0)
					.map((item, key) => {
						return (
							<li
								key={item.id}
								id={`item-${item.id}`}
								className={`slider-item${key === 0 ? ' active' : ''}`}
							>
								<a data-fancybox='gallery' href={item.src}>
									<img src={item.src} alt={item.name} />
								</a>
							</li>
						);
					})
			: null;

	let pagination =
		product !== null
			? product.images
					.filter((item, key) => key !== 0)
					.map((item, key) => {
						return (
							<li key={item.id}>
								<a href={`item-${item.id}`} onClick={addActiveClassHandler}>
									<img src={item.src} alt={item.name} width='100' />
								</a>
							</li>
						);
					})
			: null;
	if (product !== null && product.meta_data[0].value !== '') {
		pagination = [
			...pagination,
			<li key={1001}>
				<a href={`item-1001`} onClick={addActiveClassHandler}>
					<img src={Play} alt='Video indicator' width='100' />
				</a>
			</li>,
		];
		content = [
			...content,
			<li key={1001} id='item-1001' className='slider-item'>
				<a
					data-fancybox='gallery'
					href={product !== null ? product.meta_data[0].value : null}
					style={{ backgroundImage: `url(${BcgAnch})` }}
				>
					<img src={Play} alt='Poster' />
				</a>
			</li>,
		];
	}

	return (
		<div className='slider'>
			<ul className='indicators'>{pagination}</ul>
			<ul className='image-container'>
				<Fancybox>{content}</Fancybox>
			</ul>
		</div>
	);
};

export default SingleProductSlider;
