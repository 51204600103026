import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeActive } from '../../features/user-form/userFormSlice';
import { clearCart } from '../../features/cart/cartSlice';
import axios from 'axios';
import './UserForm.scss';

const apiUrl = 'https://skolskipribor.com/api/cart.php';

const UserForm = () => {
	const dispatch = useDispatch();
	const [user, setUser] = useState({
		fullName: '',
		email: '',
		phone: '',
		address: '',
		city: '',
		country: 'Srbija',
	});
	const [message, setMessage] = useState('');
	const [success, setSuccess] = useState(false);
	const { cartItems, amount, total } = useSelector((state) => state.cart);

	//const cart = useSelector((state) => state.cart);
	const { isActive } = useSelector((state) => state.userForm);

	const data = { cartItems, amount, total, ...user };

	const changeHandler = (ev) => {
		const { name, value } = ev.target;
		setUser({
			...user,
			[name]: value,
		});
	};
	const submitHandler = (ev) => {
		ev.preventDefault();
		console.log(data);
		axios
			.post(apiUrl, data)
			.then((res) => {
				console.log(res);
				setMessage('Uspješno ste poslali narudžbu. Hvala!');
				setSuccess(true);
				setTimeout(() => {
					dispatch(removeActive());
					dispatch(clearCart());
					setUser({
						fullName: '',
						email: '',
						phone: '',
						address: '',
						city: '',
						country: 'Srbija',
					});
					setMessage('');
					setSuccess(false);
				}, 2000);
			})
			.catch((err) => {
				console.log(err);
				setMessage('Nešto nije u redu. Molimo pokušajte kasnije.');
				setSuccess(false);
				setTimeout(() => {
					setMessage('');
				}, 2000);
			});
	};
	return (
		<section className={`user-form${isActive ? ' active' : ''}`}>
			<div className='form-header'>
				<h2>Podatci o vama</h2>
				<button onClick={() => dispatch(removeActive())}>X</button>
			</div>

			<form onSubmit={submitHandler}>
				<p>Sva polja obavezna</p>
				<div className='input-group'>
					<input
						type='text'
						name='fullName'
						value={user.fullName}
						onChange={changeHandler}
						placeholder='Ime i prezime'
						required={true}
					/>
					<input
						type='email'
						name='email'
						value={user.email}
						onChange={changeHandler}
						placeholder='Email'
						required={true}
					/>
				</div>
				<div className='input-group'>
					<input
						type='tel'
						name='phone'
						value={user.phone}
						onChange={changeHandler}
						placeholder='Telefon'
						required={true}
					/>

					<input
						type='text'
						name='address'
						value={user.address}
						onChange={changeHandler}
						placeholder='Ulica i broj'
						required={true}
					/>
				</div>
				<div className='input-group'>
					<input
						type='text'
						name='city'
						value={user.city}
						onChange={changeHandler}
						placeholder='Grad'
						required={true}
					/>
					<select name='country' required={true} onChange={changeHandler}>
						<option value='Srbija'>Srbija</option>
						<option value='Bosna i Hercegovina'>Bosna i Hercegovina</option>
						<option value='Crna Gora'>Crna Gora</option>
					</select>
				</div>
				<p className={`${success ? 'success' : 'error'}`}>{message}</p>
				<div className='btn-group'>
					<button type='submit'>Pošalji narudžbu</button>
				</div>
			</form>
		</section>
	);
};

export default UserForm;
