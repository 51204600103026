import React from 'react';
import { MapContainer } from 'react-leaflet/MapContainer';
import { TileLayer } from 'react-leaflet/TileLayer';
import { Marker } from 'react-leaflet/Marker';
import { Popup } from 'react-leaflet/Popup';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { Icon } from 'leaflet';
import './Map.scss';

const Map = () => {
	return (
		<div className='map-container'>
			<MapContainer
				center={[44.10806127445346, 19.292142262858096]}
				zoom={6}
				scrollWheelZoom={true}
				style={{ width: '100%', height: '560px' }}
				className='map'
			>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
				/>
				<Marker
					position={[43.322405153106814, 21.914355769585455]}
					icon={
						new Icon({
							iconUrl: markerIconPng,
							iconSize: [25, 41],
							iconAnchor: [12, 41],
						})
					}
				>
					<Popup>
						Prodaja BIC Sport kajaka, SUP dasaka i dodatne opreme Niš
					</Popup>
				</Marker>
				<Marker
					position={[44.7681335181999, 17.199341142473155]}
					icon={
						new Icon({
							iconUrl: markerIconPng,
							iconSize: [25, 41],
							iconAnchor: [12, 41],
						})
					}
				>
					<Popup>
						Prodaja BIC Sport kajaka, SUP dasaka i dodatne opreme Banja Luka
					</Popup>
				</Marker>
			</MapContainer>
		</div>
	);
};

export default Map;
