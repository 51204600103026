import React from 'react';
import { Link } from 'react-router-dom';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import BcgImage from '../../images/bg_home_02_01-1.jpg';
import './AboutHome.scss';
const AboutHome = () => {
	return (
		<section
			className='about-home'
			style={{ backgroundImage: `url(${BcgImage})` }}
		>
			<div className='container'>
				<div className='about'>
					<h2>O NAMA</h2>
					<p>
						TAHE je brend nastao povezivanjem dvije velike kompanije BIC Sport-a
						i Tahe Outdoors. Ove dvije legendarne kompanije decenijama su
						poznate po posvećenosti vodenim sportovima. Stručnost BIC Sport-a,
						njihovo iskustvo u proizvodnji i fokus na porodičnoj zabavi sada su
						kombinovani sa Tahe Outdoors. Rezultat je brend posvećen beskrajnom
						vremenu igranja na vodi sa porodicom i prijateljima svih nivoa i
						vještina.
					</p>
					<Link to='/o-nama'>
						Vidi više <HiOutlineArrowNarrowRight />
					</Link>
				</div>
			</div>
		</section>
	);
};

export default AboutHome;
