import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CartModal from './components/cart-modal/CartModal';
import Footer from './components/footer/Footer';
import NavBar from './components/navbar/NavBar';
import UserForm from './components/user-form/UserForm';
import ChooseLanguage from './components/choose-language/ChooseLanguage';

const LayOut = () => {
	const { isChoosen } = useSelector((state) => state.isLanguageChoosen);
	const conditionalConetnt = !isChoosen ? <ChooseLanguage /> : null;
	return (
		<>
			{' '}
			{conditionalConetnt}
			<NavBar />
			<CartModal />
			<UserForm />
			<Outlet />
			<Footer />
		</>
	);
};

export default LayOut;
