import React from 'react';
import SingleProductInfo from './SingleProductInfo';
import SingleProductSlider from './SingleProductSlider';
import './SingleProduct.scss';

const SingleProduct = () => {
	return (
		<article className='single-product'>
			<div className='product-container'>
				<SingleProductSlider />
				<SingleProductInfo />
			</div>
		</article>
	);
};

export default SingleProduct;
