import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	addProduct,
	onUnmount,
} from '../../features/single-product/productSlice';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import SingleProduct from '../single-product/SingleProduct';
import Header from '../header/Header';
import Loader from '../loader/Loader';

const credential =
	'consumer_key=ck_1427e438064835f715d903b3bc1237a900a4aa70&consumer_secret=cs_fe3e08977975caf69e0d2028144859d482730be8';

const Product = () => {
	const dispatch = useDispatch();
	const { proizvodId } = useParams('proizvodId');

	const { product, isLoading } = useSelector((state) => state.product);
	const [path, setPath] = useState('');
	useEffect(() => {
		setPath(window.location.href);
	}, []);

	useEffect(() => {
		axios
			.get(
				`https://newproject.store/kajaci/wp-json/wc/v3/products/${proizvodId}?${credential}`
			)
			.then((res) => {
				dispatch(addProduct(res.data));
			})
			.catch((err) => {
				console.log(err);
			});
		return () => {
			dispatch(onUnmount());
		};
	}, [proizvodId, dispatch]);
	return (
		<>
			<Helmet>
				<title>{product ? product.yoast_head_json.title : ''}</title>
				<meta
					name='description'
					content={product ? product.yoast_head_json.og_description : ''}
				/>
				<meta property='og:url' content={path} />
				<meta property='og:type' content='article' />
				<meta
					property='og:title'
					content={product ? product.yoast_head_json.title : ''}
				/>
				<meta
					property='og:description'
					content={product ? product.yoast_head_json.og_description : ''}
				/>
				<meta
					property='og:image'
					content={product !== null ? product.images[1].src : null}
				/>
			</Helmet>
			<div className='product'>
				{isLoading ? (
					<Loader />
				) : (
					<>
						<Header
							bcgImage={product !== null ? product.images[0].src : null}
							title={product !== null ? product.name : null}
						/>
						<SingleProduct />
					</>
				)}
			</div>
		</>
	);
};

export default Product;
