import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import { addToCart, calculateTotals } from '../../features/cart/cartSlice';

import './SingleProductInfo.scss';

const SingleProductInfo = () => {
	const { proizvodId } = useParams();

	const dispatch = useDispatch();
	const { lang } = useSelector((state) => state.lang);
	const { currency } = useSelector((state) => state.currency);
	const { product } = useSelector((state) => state.product);
	const { cartItems } = useSelector((state) => state.cart);
	const [convertedPrice, setConvertedPrice] = useState('');
	const [disabled, setDisabled] = useState(false);
	const price = product !== null ? product.regular_price : '1';
	const weight = product !== null ? product.weight.split('-') : null;
	const title = product !== null ? product.name : null;
	const desc = product !== null ? product.description : null;

	useEffect(() => {
		setDisabled(
			cartItems.length > 0 &&
				cartItems.some((item) => item.id === parseInt(proizvodId))
		);
	}, [cartItems, proizvodId]);

	useEffect(() => {
		switch (lang) {
			case 'RS':
				setConvertedPrice(
					`${Math.round(
						parseFloat(price) *
							parseFloat(currency !== null ? currency[0].description : '1')
					)}RSD`
				);
				break;
			case 'BA':
				setConvertedPrice(
					`${Math.round(
						parseFloat(price) *
							parseFloat(currency !== null ? currency[1].description : '1')
					)}BAM`
				);
				break;
			case 'ME':
				setConvertedPrice(`${price}EUR`);
				break;
			default:
				setConvertedPrice(
					`${Math.round(
						parseFloat(price) *
							parseFloat(currency !== null ? currency[0].description : '1')
					)}RSD`
				);
				break;
		}
	}, [lang, product, currency, price]);
	const addToCartHandler = () => {
		dispatch(
			addToCart({
				id: product.id,
				productName: product.name,
				qty: 1,
				image: product.images[1].src,
				price: product.price,
				disabled: true,
			})
		);
		dispatch(calculateTotals());
	};
	return (
		<div className='single-product-info'>
			<h2>{title}</h2>
			<div className='product-info-main-description'>{parse(desc)}</div>
			<div className='product-info-main-key-characteristics'>
				<div className='key-characteristics-header'>
					<h3>Specifikacije</h3>
				</div>
				<ul className='key-characteristics'>
					<li>
						<span>Dimenzije</span>
						<span>
							{product !== null ? `${product.dimensions.length} cm` : null} /{' '}
							{product !== null ? `${product.dimensions.width}  cm` : null} /{' '}
							{product !== null ? `${product.dimensions.height} L` : null}
						</span>
					</li>
					<li>
						<span>Težina</span>
						<span>{weight !== null ? weight[0] : null} kg</span>
					</li>
					<li>
						<span>Nosivost</span>
						<span>{weight !== null ? weight[1] : null} kg</span>
					</li>
					<li>
						<span>Cijena:</span>
						<span>{convertedPrice}</span>
					</li>
				</ul>
			</div>
			<button onClick={addToCartHandler} disabled={disabled}>
				Dodaj u korpu
			</button>
		</div>
	);
};

export default SingleProductInfo;
