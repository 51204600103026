import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import Header from '../header/Header';
import SingleCategory from '../single-category/SingleCategory';
import './Category.scss';

const Category = () => {
	const { categories } = useSelector((state) => state.categories);

	const [path, setPath] = useState('');
	useEffect(() => {
		setPath(window.location.href);
	}, []);

	const equipment =
		categories !== null
			? categories.product_categories.filter((items) => {
					return items.parent === 19;
			  })
			: null;
	const categoryContent =
		equipment !== null
			? equipment.map((category) => {
					return (
						<SingleCategory
							key={category.id}
							category={`proizvodi/${category.id}`}
							title={category.name}
							bcgImage={category.image}
							content={category.description}
						/>
					);
			  })
			: null;

	return (
		<>
			<Helmet>
				<title>Tahe oprema i zamjenski dijelovi za sve kategorije</title>
				<meta
					name='description'
					content='Sve za kajakaštvo , SUP daske, daske za jedrenje na vjetru i oprema za sve kategorije'
				/>
				<meta property='og:url' content={path} />
				<meta property='og:type' content='website' />
				<meta
					property='og:title'
					content='Tahe oprema i zamjenski dijelovi za sve kategorije'
				/>
				<meta
					property='og:description'
					content='Sve za kajakaštvo , SUP daske, daske za jedrenje na vjetru i oprema za sve kategorije'
				/>
				<meta
					property='og:image'
					content='https://newproject.store/kajaci/wp-content/uploads/2022/06/kategorije-2-meta-min.jpg'
				/>
			</Helmet>
			<div className='equipment-categories'>
				<Header
					bcgImage={equipment !== null ? equipment[0].image : null}
					title='Dodatna oprema'
					content={null}
				/>
				<div className='equipment-container'>{categoryContent}</div>
			</div>
		</>
	);
};

export default Category;
