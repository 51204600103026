import React from 'react';
import './Header.scss';

const Header = (props) => {
	return (
		<div
			className='header'
			style={{ backgroundImage: `url(${props.bcgImage})` }}
		>
			<div className='text-content'>
				<h1>{props.title}</h1>
				<p>{props.content}</p>
			</div>
		</div>
	);
};

export default Header;
