import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	cartItems: [],
	amount: 0,
	total: 0,
	isLoading: true,
};

const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		addToCart: (state, { payload }) => {
			state.cartItems.push(payload);
			state.amount = state.amount + 1;
		},
		clearCart: (state) => {
			state.cartItems = [];
			state.amount = 0;
			state.total = 0;
		},
		removeItem: (state, action) => {
			const itemId = action.payload;
			state.cartItems = state.cartItems.filter((item) => item.id !== itemId);
		},
		increase: (state, { payload }) => {
			const cartItem = state.cartItems.find((item) => item.id === payload.id);
			cartItem.qty = cartItem.qty + 1;
		},
		decrease: (state, { payload }) => {
			const cartItem = state.cartItems.find((item) => item.id === payload.id);
			if (cartItem.qty > 1) {
				cartItem.qty = cartItem.qty - 1;
			} else {
				return;
			}
		},

		calculateTotals: (state) => {
			let amount = 0;
			let total = 0;
			state.cartItems.forEach((item) => {
				amount += item.qty;
				total += item.qty * parseInt(item.price);
			});
			state.amount = amount;
			state.total = total;
		},
	},
});

export const {
	addToCart,
	clearCart,
	removeItem,
	increase,
	decrease,
	calculateTotals,
} = cartSlice.actions;

export default cartSlice.reducer;
