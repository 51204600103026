import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import Header from '../header/Header';
import Map from '../map/Map';
import BcgImg from '../../images/SIC_Photos_Website_2022_3840x1600px_SUP_2.jpg';
import { BsTelephone } from 'react-icons/bs';
import { BsEnvelope } from 'react-icons/bs';
import { MdLocationOn } from 'react-icons/md';

import './Contact.scss';

const apiUrl = 'https://skolskipribor.com/api/kontakt.php';

const Contact = () => {
	const [data, setData] = useState({
		fullName: '',
		email: '',
		phone: '',
		subject: '',
		message: '',
	});
	const [message, setMessage] = useState('');
	const [success, setSuccess] = useState(false);
	const [path, setPath] = useState('');
	useEffect(() => {
		setPath(window.location.href);
	}, []);

	const changeHandler = (ev) => {
		const { name, value } = ev.target;
		setData({
			...data,
			[name]: value,
		});
	};
	const submitHandler = (ev) => {
		ev.preventDefault();
		console.log('Form is submited');
		axios
			.post(apiUrl, data)
			.then((res) => {
				console.log(res);
				setMessage('Poruka je uspješno poslana. Hvala!');
				setSuccess(true);
				setTimeout(() => {
					setData({
						fullName: '',
						email: '',
						phone: '',
						subject: '',
						message: '',
					});
					setMessage('');
					setSuccess(false);
				}, 2000);
				setData({
					fullName: '',
					email: '',
					phone: '',
					subject: '',
					message: '',
				});
			})
			.catch((err) => {
				console.log(err);
				setMessage('Nešto nije u redu. Molimo pokušajte kasnije.');
				setSuccess(false);
				setTimeout(() => {
					setMessage('');
				}, 2000);
			});
	};
	return (
		<>
			<Helmet>
				<title>Budimo u kontaktu, podrska 0-24h</title>
				<meta
					name='description'
					content='Za bilo koje pitanje, možete nam se obratiti putem kontakt forme i očekujte odgovor u roku od 24h.
'
				/>
				<meta property='og:url' content={path} />
				<meta property='og:type' content='website' />
				<meta property='og:title' content='Budimo u kontaktu, podrska 0-24h' />
				<meta
					property='og:description'
					content='Za bilo koje pitanje, možete nam se obratiti putem kontakt forme i očekujte odgovor u roku od 24h.
'
				/>
				<meta
					property='og:image'
					content='https://newproject.store/kajaci/wp-content/uploads/2022/06/kontakt-meta-min.jpg'
				/>
			</Helmet>
			<div className='contact'>
				<Header
					bcgImage={BcgImg}
					title='Budimo u kontaktu'
					content='Imamo fleksibilnu podršku svjetske klase putem e-pošte.'
				/>
				<section className='contact-form'>
					<div className='container'>
						<div className='info'>
							<h2>Kontakt info</h2>
							<p>
								Garantujemo da ćete moći da riješite bilo koji problem u roku od
								24 sata. Ovdje odmah kontaktirate podršku.
							</p>
							<ul>
								<li>
									<h3>Telefon: </h3>
									<div className='info-items'>
										<span>
											<BsTelephone /> +381 63 109 21 00 Srbija
										</span>
										<span>
											<BsTelephone /> +387 51 264 888 BiH
										</span>
										<span>
											<BsTelephone /> +382 69 691 910 Crna Gora
										</span>
									</div>
								</li>
								<li>
									<h3>Lokacija: </h3>
									<div className='info-items'>
										<span>
											<MdLocationOn /> Bul.Nemanjica19/56 Niš Srbija
										</span>
										<span>
											<MdLocationOn /> Gavre Vukčkovića 4 Banja Luka BiH
										</span>
									</div>
								</li>
								<li>
									<h3>Email: </h3>
									<div className='info-items'>
										<span>
											<BsEnvelope /> gopaddling.rs@gmail.com Srbija
										</span>
										<span>
											<BsEnvelope /> gopaddling.me@gmail.com Crna Gora
										</span>
										<span>
											<BsEnvelope /> info@itexpertstudio.com BiH
										</span>
									</div>
								</li>
							</ul>
						</div>
						<div className='form'>
							<h2>Kontakt forma</h2>
							<form onSubmit={submitHandler}>
								<div className='form-group'>
									<input
										type='text'
										name='fullName'
										value={data.fullName}
										placeholder='Puno ime'
										onChange={changeHandler}
										required={true}
									/>
									<input
										type='email'
										name='email'
										value={data.email}
										placeholder='Email'
										onChange={changeHandler}
										required={true}
									/>
								</div>
								<div className='form-group'>
									<input
										type='tel'
										name='phone'
										value={data.phone}
										placeholder='Telefon'
										onChange={changeHandler}
										required={true}
									/>
									<input
										type='text'
										name='subject'
										value={data.subject}
										placeholder='Subjekt'
										onChange={changeHandler}
									/>
								</div>
								<div className='msg'>
									<textarea
										name='message'
										value={data.message}
										placeholder='Poruka'
										onChange={changeHandler}
										required={true}
									></textarea>
								</div>
								<p className={`${success ? 'success' : 'error'}`}>{message}</p>
								<div className='btn-group'>
									<button type='submit'>Pošalji</button>
								</div>
							</form>
						</div>
					</div>
				</section>
				<Map />
			</div>
		</>
	);
};

export default Contact;
