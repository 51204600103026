import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	categories: null,
	isLoading: true,
};

const categoriesSlice = createSlice({
	name: 'catgories',
	initialState,
	reducers: {
		addCategories: (state, { payload }) => {
			state.categories = payload;
			state.isLoading = false;
		},
	},
});
export const { addCategories } = categoriesSlice.actions;
export default categoriesSlice.reducer;
