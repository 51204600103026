import React from 'react';
import Header from '../header/Header';
import Error404Bcg from '../../images/404.png';
import './404.scss';
const Error404 = () => {
	return (
		<div className='page-not-found'>
			<Header
				bcgImage={Error404Bcg}
				title='Error 404'
				content='Stranica nije pronađena!'
			/>
		</div>
	);
};

export default Error404;
