import React from 'react';
import {
	increase,
	decrease,
	calculateTotals,
	removeItem,
} from '../../features/cart/cartSlice';
import { useDispatch } from 'react-redux';

const CartyItem = ({ id, image, productName, qty, price, curr, valute }) => {
	const dispatch = useDispatch();
	return (
		<li>
			<div className='image-container'>
				<img src={image} height='60' alt={productName} />
			</div>
			<div className='content'>
				<h4>{productName}</h4>
			</div>
			<div className='qty'>
				<button
					onClick={() => {
						dispatch(decrease({ id }));
						dispatch(calculateTotals());
					}}
				>
					-
				</button>
				<span>{qty}</span>
				<button
					onClick={() => {
						dispatch(increase({ id }));
						dispatch(calculateTotals());
					}}
				>
					+
				</button>
			</div>
			<div className='price'>
				<span>
					{Math.round(parseFloat(price) * parseFloat(curr))}
					{valute}
				</span>
			</div>
			<div className='remove'>
				<button
					onClick={() => {
						dispatch(removeItem(id));
						dispatch(calculateTotals());
					}}
				>
					X
				</button>
			</div>
		</li>
	);
};

export default CartyItem;
