import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import BcgImg from '../../images/SIC_Photos_Website_2021_3840x1400px_86F2DB0C-BAA8-4B6B-B9B4-71F644A26523_1_201_a.jpg';
import Header from '../header/Header';
import './About.scss';

const About = () => {
	const [path, setPath] = useState('');
	useEffect(() => {
		setPath(window.location.href);
	}, []);
	return (
		<>
			<Helmet>
				<title>Tahe je brend koji osvaja svijet</title>
				<meta
					name='description'
					content='Bilo da želite da "hodate po vodi", da veslate ili da jedrite na vjetru, Tahe Vam nudi najkvalitetniju opremu za bilo koji vodeni sport.'
				/>
				<meta property='og:url' content={path} />
				<meta property='og:type' content='website' />
				<meta property='og:title' content='Tahe je brend koji osvaja svijet' />
				<meta
					property='og:description'
					content='Bilo da želite da "hodate po vodi", da veslate ili da jedrite na vjetru, Tahe Vam nudi najkvalitetniju opremu za bilo koji vodeni sport.'
				/>
				<meta
					property='og:image'
					content='https://newproject.store/kajaci/wp-content/uploads/2022/06/o-nama-meta-min.jpg'
				/>
			</Helmet>
			<div className='about'>
				<Header
					bcgImage={BcgImg}
					title='O nama'
					content='BIC SPORT POSTAJE TAHE'
				/>
				<section className='about-info'>
					<div className='container-lg'>
						<div className='video'>
							<iframe
								src='https://www.youtube.com/embed/7dvKs_p-6f0'
								title='YouTube video player'
								frameBorder='0'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
								allowFullScreen
							></iframe>
						</div>
						<div className='content'>
							<h2>BIC SPORT POSTAJE TAHE</h2>
							<p>
								TAHE je brend nastao povezivanjem dvije velike kompanije BIC
								Sport-a i Tahe Outdoors. Ove dvije legendarne kompanije
								decenijama su poznate po posvećenosti vodenim sportovima.
								Stručnost BIC Sport-a, njihovo iskustvo u proizvodnji i fokus na
								porodičnoj zabavi sada su kombinovani sa Tahe Outdoors. Rezultat
								je brend posvećen beskrajnom vremenu igranja na vodi sa
								porodicom i prijateljima svih nivoa i vještina. Sa tim duhom
								počinje TAHE, nadovezujući se na legendarnu osnovu evropske
								proizvodnje i istraživanja u dizajnu kajaka, SUP I WINDSURF
								dasaka. Proizvodnja se oslanja na ekološki prihvatljive
								proizvodne procese i kvalitetne proizvode po pristupačnoj
								cijeni. Takođe, ulaganje u inovacije i razvoj vodenih sportova.
							</p>
							<p>
								„TAHE PRIHVATA KLJUČNE VREDNOSTI BIC SPORTA I TAHE OUTDOORS DOK
								GRADI NOVO NASLIJEĐE OKO IDEJE BESKONAČNOG VREMENA IGRE NA VODI.
								SVI MI SANJAMO O BESKONAČNOM LETU, ZABAVI I AVANTURI SA
								PORODICOM, PRIJATELJIMA I TAJ SAN NAS UJEDINJUJE I DAJE NAM DA
								OSJETIMO ONO NAJLJEPŠE OD ŽIVOTA. TO JE ONO ŠTO TRAŽIMO I NADAMO
								SE DA ĆEMO NAĆI, SVAKI PUT KADA SE OTISNEMO NA VODU."
							</p>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default About;
