import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	lang: 'RS',
};

const langSlice = createSlice({
	name: 'lang',
	initialState,
	reducers: {
		addLang: (state, action) => {
			state.lang = action.payload;
		},
	},
});

export const { addLang } = langSlice.actions;

export default langSlice.reducer;
